import React from "react";
import { Redirect, Route, RouteProps } from "wouter";
import { UrlPaths } from "./urlPaths";

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const token = localStorage.getItem("token");
  const expiration = localStorage.getItem("expiration");

  const isTokenExpired = expiration
    ? new Date().getTime() > new Date(expiration).getTime()
    : true;

  return token && !isTokenExpired ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to={UrlPaths.Home} />
  );
};

export default ProtectedRoute;