import { JwtPayload, jwtDecode } from "jwt-decode";
import { useCallback, useState } from "react";
import { UrlPaths } from "../../utils/urlPaths";
import { Role } from "../../utils/types/roleTypes";
import { useLocation } from "wouter";

type CustomJWTPayload = {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": Array<string> | null;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
} & JwtPayload;

export default function useLogin() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [, setLocation] = useLocation();

  const sendLogin = useCallback(
    async (loginData: { userName: string; password: string }) => {
      const headers = { "Content-type": "application/json" };
      const url =
        "https://pls-api-appservice.azurewebsites.net/api/Authenticate/login";

      setIsLoading(true);
      setError(null);

      const { userName, password } = loginData;

      try {
        const response = await fetch(url, {
          headers,
          body: JSON.stringify({
            username: userName,
            password: password,
          }),
          method: "POST",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const jsonResponse = await response.json();
        if (
          !jsonResponse.hasOwnProperty("token") ||
          !jsonResponse.hasOwnProperty("expiration")
        ) {
          throw new Error(
            "Json response from login is missing token or expiration field"
          );
        }

        const decodedToken =
          jsonResponse != null
            ? (jwtDecode(jsonResponse?.token) as CustomJWTPayload)
            : null;

        if (decodedToken != null) {
          const roles =
            decodedToken[
              "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ];

          let role = Role.EMPLOYEE;
          if (roles?.includes(Role.ADMIN.toString())) {
            role = Role.ADMIN;
          } else if (roles?.includes(Role.EMPLOYEE.toString())) {
            role = Role.EMPLOYEE;
          }
          const token = jsonResponse.token;
          const expirationTime = decodedToken.exp ?? 0;
          const currentUser = jsonResponse.fullName

          localStorage.setItem("role", role);
          localStorage.setItem("token", token.toString());
          localStorage.setItem("expiration", String(expirationTime));
          localStorage.setItem("currentUser", currentUser);

          if (role === Role.ADMIN || role === Role.EMPLOYEE) {
            setIsLoading(false);
            setLocation(UrlPaths.Dashboard);
          }
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setLocation]
  );

  return { sendLogin, isLoading, error };
}
