import { Button } from "@mui/material";
import { UrlPaths } from "../utils/urlPaths";

export default function FourOFour() {
  return (
    <>
      <div>Not found</div>
      <Button href={UrlPaths.Home}>Go Back to homepage</Button>
    </>
  );
}
