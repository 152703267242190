import { Box, Button, Menu, MenuItem } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { visuallyHidden } from '@mui/utils';
import { useMediaQuery, useTheme } from '@mui/material';

import useGetClientData  from '../../hooks/clients/useGetClients';
import TableSearch from '../../shared/TableSearch';

type Order = 'asc' | 'desc';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0F3354",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  backgroundColor: '#0F3354',
  '&.MuiTableSortLabel-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root.Mui-active': {
    color: 'white',
  },
  '&.MuiTableSortLabel-active': {
    fontSize: 14,
    color: 'white'
  },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgba(0, 180, 204, 0.08)",
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Clients() {
  const { clientData, totalEntries, fetchData } =
  useGetClientData();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('company');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  console.log('isSmallScreen', isSmallScreen)

  
  useEffect(() => {
    const fetchOrder = order === "asc" ? true : false; 
    fetchData(pageNumber, searchTerm, rowsPerPage, orderBy, fetchOrder);
  }, [fetchData, pageNumber, searchTerm, rowsPerPage, order, orderBy]);

  const clientTableData = clientData ?? [];

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    console.log('property:', property, order)
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };

  return(
    <Box component="main" sx={{ flexGrow: 1, p: 4, marginTop: "35px" }}>
        <div style={{backgroundColor: "white", color:"#0F3354", padding: "10px 10px", fontSize:"36px", marginTop: "10px", marginBottom:"10px"}}>Clients</div>
        <div style={{backgroundColor: "white"}}>
          <div style={{marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <TableSearch
              setSearchTerm={setSearchTerm}
              setPageNumber={setPageNumber}
              inProgressSearchTerm={inProgressSearchTerm}
              setInProgressSearchTerm={setInProgressSearchTerm}
              page={"Clients"}
            ></TableSearch> 
            <div>
              <Button variant="contained" size="medium" style={{marginRight: "20px"}}>Add Client</Button>
            </div>
            
          </div>
          {/* <TableContainer sx={{ overflowY: "visible", height: "70%"}}> */}
            {/* <TableContainer sx={{maxHeight: 400, height: "100%", overflowY: "visible"}}> */}
            {/* <TableContainer sx={{height: 'calc(100vh - 220px)', overflowY: "visible", marginBottom:"20px"}}> */}
            <TableContainer sx={{ height: isSmallScreen ? "58vh": "60vh", overflowY: "visible"}}>
              {/* <StyledTable stickyHeader sx={{ minWidth: 650 }} size="medium" aria-label="simple table"> */}
              <Table sx={{ minWidth: 650 }} size={isSmallScreen ? 'small' : 'medium'}>
                <TableHead >
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <StyledTableSortLabel
                        active={orderBy === 'company'}
                        direction={order}
                        onClick={createSortHandler('company')}                   
                        >Company
                        {orderBy === 'company' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'firstName'}
                        direction={order}
                        onClick={createSortHandler('firstName')}                   
                        >Contact
                        {orderBy === 'firstName' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'address'}
                        direction={order}
                        onClick={createSortHandler('address')}                   
                        >Address
                        {orderBy === 'address' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'city'}
                        direction={order}
                        onClick={createSortHandler('city')}                   
                        >City
                        {orderBy === 'city' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'zipcode'}
                        direction={order}
                        onClick={createSortHandler('zipcode')}                   
                        >Zip
                        {orderBy === 'zipcode' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      Active Labs
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'status'}
                        direction={order}
                        onClick={createSortHandler('status')}                   
                        >Status
                        {orderBy === 'status' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientTableData.map((row) => (
                    <StyledTableRow
                      key={row.company}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.company}
                      </StyledTableCell>
                      <StyledTableCell >{row.firstname + " " + row.lastname}</StyledTableCell >
                      <StyledTableCell >{row.address}</StyledTableCell>
                      <StyledTableCell >{row.city}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.zipcode}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.clientLabs}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.status}</StyledTableCell>
                      <StyledTableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVertIcon/>
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <hr></hr>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalEntries}
              rowsPerPage={rowsPerPage}
              page={pageNumber-1}
              onPageChange={(handleChangePage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ boxShadow: 0 }}
            >
              <MenuItem onClick={handleClose}>View</MenuItem>
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
        </div>
        <Box>
    </Box>
    </Box>
  )
}