import LogoSvg from "../../assets/PLS Logo-svg.svg";
import styled from "styled-components";

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: baseline
`;

export default function LogoContainer() {
  return (
    <StyledLogoContainer>
      <img src={LogoSvg} alt="logo" style={{width: "140px"}}/>
    </StyledLogoContainer>
  );
}