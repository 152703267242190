import { Box } from "@mui/material";
import Placeholder from "../placeholder/Placeholder";

export default function SalesOrders() {
  return(
    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "35px" }}>
        <h1 style={{backgroundColor: "white", color:"#0F3354", padding: "15px 10px"}}>Sales Orders</h1>
        <Placeholder/>
    </Box>
  )
}