import Box from '@mui/material/Box'
import Placeholder from '../placeholder/Placeholder'

export default function Dashboard() {
  return(
    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "35px" }}>
        <h1 style={{backgroundColor: "white", color:"#0F3354", padding: "15px 10px"}}>Dashboard</h1>
        <Placeholder/>
    </Box>
  )

}