import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";

const PAGE_SIZE = 10;

export default function useGetClientData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (pageNumber: number, searchTerm: string | null, pageSize:number | null, orderBy: string|null, ascending: boolean) => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlClient?PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${pageNumber}&OrderBy=${orderBy}&Ascending=${ascending}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientData(responsejson.clients);
          setTotalEntries(responsejson.metaData.totalEntries);
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoading(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    },
    [totalEntries]
  );

  const clientData = useMemo(() => {
    if (cData == null) {
      return null;
    }
    return cData.map((c) => {
        return {
          clientId: c.client.clientId,
          company: c.client.company,
          firstname: c.client.firstname,
          lastname: c.client.lastname,
          address: c.client.address,
          city: c.client.city,
          stateCode: c.client.stateCode,
          zipcode: c.client.zipcode,
          status: c.client.status,
          clientLabs: c.clientLabs.length
        }
      }
    );
  }, [cData]);

  return { clientData, totalPages, totalEntries, isLoading, error, fetchData };
}
