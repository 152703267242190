import { useLocation } from "wouter";
import { UrlPaths } from "../../utils/urlPaths";

export default function useLogout() {
  const [, setLocation] = useLocation();

  const logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("currentUser");
    setLocation(UrlPaths.Home);
  };

  return logout;
}