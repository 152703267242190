import ConstructionIcon from '@mui/icons-material/Construction';

export default function Placeholder() {

  return(
   <div style={{backgroundColor: "white"}}>
    <h1 style={{display: "flex", justifyContent: "center", alignContent: "center", paddingTop: "20px", color:"#0F3354"}}>Coming Soon</h1>
    <div style={{padding: "0px 35%", textAlign: "center"}}>Thanks for your patience! This feature is still in development, and we’re working hard to bring it to you. Stay tuned for updates and check back soon for more details. In the meantime, feel free to explore the rest of our site!</div>
    <div style={{display: "flex", justifyContent: "center"}}>
      <ConstructionIcon sx={{ fontSize: "20em", color: "lightblue" }}/>
    </div>
  </div>
  )

}
