import { TextField, Button } from "@mui/material"

type SearchSettings = {
    inProgressSearchTerm: string,
    setInProgressSearchTerm: Function,
    setSearchTerm: Function,
    setPageNumber: Function,
    page: string,
}

export default function TableListSearch ({ inProgressSearchTerm, setInProgressSearchTerm, setSearchTerm, setPageNumber, page }: SearchSettings) {
    const updateSearchTerm = () => {
        if (inProgressSearchTerm === "") {
            setSearchTerm(null);
            setPageNumber(1);
        } else {
            setSearchTerm(inProgressSearchTerm);
            setPageNumber(1);
        }
    };

    return (
        <div style={{ paddingBottom: "20px", paddingTop: "20px", display: "flex"}}>
            <TextField
              id="fileSearch"
              type="text"
              placeholder={`Search ${page}...`}
              aria-label="search clients input"
              aria-describedby="fileSearch"
              value={inProgressSearchTerm}
              onChange={({ target: { value } }) =>
                setInProgressSearchTerm(value)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateSearchTerm()
                }
              }}
              style={{backgroundColor: "white"}}
              size="small"
            />
            <Button variant="contained" style={{marginLeft: "5px"}} onClick={()=> updateSearchTerm()}>Search</Button>
          </div>
    )
}