import { createTheme } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import "@mui/styles";
// import { ThemeOptions } from '@mui/material/styles';

// Update the Button's color options to include a salmon option
declare module '@mui/material/AppBar' {
  interface ButtonPropsColorOverrides {
    salmon: true;
  }
}


let theme = createTheme({
  palette: {
    
    primary: {
      light: '#0a233a',
      main: '#0F3354',
      dark: '#3f5b76',
      contrastText: '#fff',
    },
    secondary: {
      light: '#007d8e',
      main: '#00B4CC',
      dark: '#33c3d6',
      contrastText: '#000',
    },
    info: {
      light: '#ff7961',
      main: '#4f5599',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    salmon: theme.palette.augmentColor({
      color: {
        main: '#FF5733',
      },
      name: 'salmon',
    }),
  },
});



// export const themeOptions: ThemeOptions = {
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#0F3354',
//     },
//     secondary: {
//       main: '#00B4CC',
//     },
//     info: {
//       main: '#4f5599',
//     },
//     error: {
//       main: '#b0134c',
//     },
//     warning: {
//       main: '#863f90',
//     },
//     success: {
//       main: '#009688',
//     },
//   },
// };

// const theme = {
//   colors: {
//     darkPrimary: "#0F3354",
//     lightPrimary: "#C1C7D2",
//     darkSecondary: "#00B4CC",
//     lightSecondary: "#D2ECF2",  
//   },
// };

export default theme;